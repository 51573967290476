import logo from './icon.png';
import logoSharedBuys from './logosharedbuys.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header >
        <div className="top-bar">
          <img src={logo} alt="Logo" className="logo" />
          <h1 className="page-name">Mattem Dev</h1>
        </div>
      </header>

      <main className="main-content">
        <div className="cards-container">
          <div className="card">
            <h2>Shared Buys</h2>
            <div className="container-logo-app">
              <img src={logoSharedBuys} alt="Logo" className="logoApps" />
            </div>
            <p>Aplicación para administrar tus listas de compras</p>
          </div>
          {/* 
          <div className="card">
            <h2>Título de la Tarjeta 2</h2>
            <p>Contenido de la tarjeta 2</p>
          </div>
            */}
        </div>
      </main>

      <footer className="footer">
        <div className="social-links">
          {/* 
             <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
            <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
            <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a>
            */}
          <a href="/home" target="_blank" rel="noopener noreferrer">Home</a>
        </div>
        <p className="policies-text">
          © 2024 MattemDev. Todos los derechos reservados. <br />
          Consulta nuestras <a href="/politicas">políticas de privacidad</a> y <a href="/terminos">términos de servicio</a>.
        </p>
      </footer>
    </div>
  );
}

export default App;
